import image_one_one from "../errorTypes/fejltype1_1.png";
import image_one_two from "../errorTypes/fejltype1_2.png";
import image_one_three from "../errorTypes/fejltype1_3.png";
import image_one_four from "../errorTypes/fejltype1_4.png";
import image_one_five from "../errorTypes/fejltype1_5.png";
import image_one_six from "../errorTypes/fejltype1_6.png";
import image_one_seven_bw from "../errorTypes/fejltype1_7BW.png";
import image_one_seven_fw from "../errorTypes/fejltype1_7FW.png";
import image_one_eight from "../errorTypes/fejltype1_8.png";
import image_one_nine from "../errorTypes/fejltype1_9.png";
import image_one_ten from "../errorTypes/fejltype1_10.png";
import image_one_eleven from "../errorTypes/fejltype1_11.png";
import image_one_twelve_bw from "../errorTypes/fejltype1_12BW.png";
import image_one_thirteen from "../errorTypes/fejltype1_13.png";
import image_one_fourteen from "../errorTypes/fejltype1_14.png";
import image_one_fifteen from "../errorTypes/fejltype1_15.png";
import image_one_sixteen from "../errorTypes/fejltype1_16.png";
import image_one_seventeen from "../errorTypes/fejltype1_17.png";
import image_one_nineteen from "../errorTypes/fejltype1_19.png";
import image_one_twenty from "../errorTypes/fejltype1_20.png";
import image_one_twentyone from "../errorTypes/fejltype1_21.png";
import image_two_six from "../errorTypes/fejltype2_6.png";
import image_two_seven from "../errorTypes/fejltype2_7.png";
import image_two_eight from "../errorTypes/fejltype2_8.png";
import image_two_nine from "../errorTypes/fejltype2_9.png";
import image_two_twelve_one from "../errorTypes/fejltype2_12_1.png";
import image_two_twelve_two from "../errorTypes/fejltype2_12_2.png";
import image_two_twelve_three from "../errorTypes/fejltype2_12_3.png";
import image_three_one_plade from "../errorTypes/fejltype3_1Plade.png";
import image_three_one_tube from "../errorTypes/fejltype3_1Tube.png";
import image_three_two from "../errorTypes/fejltype3_2.png";

const maxDecimalPoints = 2;

const err = {
	T_ERR: [{ name: "Fejl", message: "Fejl Tykkelsen er for lav" }],
	A_ERR: [{ name: "Fejl", message: "Fejl A-målet er ikke sat" }],
	S_ERR: [{ name: "Fejl", message: "Fejl Stumpsømmets tykkelse er ikke sat" }],
	B_ERR: [{ name: "Fejl", message: "Fejl Bredden er ikke sat" }],
};

const msg = {
	PartlyForbidden: "Ikke tilladt dog kun lokalt, se forklaring",
	FORBIDDEN: "Ikke tilladt",
	ALLOWED: "Tilladt",
	PARTLY: "Tilladt lokalt",
	SHORT: "Korte fejl: h ≤ $$mm",
	D_MSG: "d ≤ $$mm",
	H_MSG: "h ≤ $$mm",
	HL_MSG: "h eller l ≤ $$mm",
	V_MSG: "Vinkel ≥ $$ grader",
};

const calculations = {
	1.1: [
		{
			name: "D, C, B",
			msg: () => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	1.2: [
		{
			name: "D, C, B",
			msg: () => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	1.3: [
		{
			name: "D",
			max: (t) => (t > 3 ? 3 : ""),
			msg: (t) => msg.D_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.3 * x,
		},
		{
			name: "C",
			max: (t) => 3,
			msg: (t) => (t > 3 ? msg.D_MSG : msg.FORBIDDEN),
			calculation: (self, t, x, b, weldingtype) => 0.2 * x,
		},
		{
			name: "B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	1.4: [
		{
			name: "D",
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.2 * t,
			max: () => 2,
		},
		{
			name: "C",
			msg: (t) => (t > 3 ? msg.H_MSG : msg.FORBIDDEN),
			calculation: (self, t, x, b, weldingtype) => 0.1 * t,
			max: () => 1,
		},
		{
			name: "B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	1.5: [
		{
			name: "D, C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	1.6: [
		{
			name: "D",
			msg: (t) => msg.SHORT,
			calculation: (self, t, x, b, weldingtype) => 0.2 * t,
			max: () => 2,
		},
		{
			name: "C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	1.7: [
		{
			name: "D",
			max: (t) => (t > 3 ? 1 : ""),
			msg: (t) => (t > 3 ? msg.H_MSG : msg.SHORT),
			calculation: (self, t, x, b, weldingtype) => 0.2 * t,
		},
		{
			name: "C",
			max: (t) => (t > 3 ? 0.5 : ""),
			msg: (t) => (t > 3 ? msg.H_MSG : msg.SHORT),
			calculation: (self, t, x, b, weldingtype) => 0.1 * t,
		},
		{
			name: "B",
			max: (t) => (t > 3 ? 0.5 : ""),
			msg: (t) => (t > 3 ? msg.H_MSG : msg.FORBIDDEN),
			calculation: (self, t, x, b, weldingtype) => 0.5 * t,
		},
	],
	1.8: [
		{
			name: "D",
			max: (t) => (t > 3 ? 2 : ""),
			msg: (t) => msg.SHORT,
			calculation: (self, t, x, b, weldingtype) =>
				t > 3 ? 0.2 * t : 0.2 + 0.1 * t,
		},
		{
			name: "C",
			max: (t) => (t > 3 ? 1 : ""),
			msg: (t) => msg.SHORT,
			calculation: (self, t, x, b, weldingtype) => 0.1 * t,
		},
		{
			name: "B",
			max: (t) => (t > 3 ? 0.5 : ""),
			msg: (t) => (t > 3 ? msg.SHORT : msg.FORBIDDEN),
			calculation: (self, t, x, b, weldingtype) => 0.05 * t,
		},
	],
	1.9: [
		{
			name: "D",
			max: (t) => 10,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 1 + 0.25 * b,
		},
		{
			name: "C",
			max: (t) => 7,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 1 + 0.15 * b,
		},
		{
			name: "B",
			max: (t) => 5,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 1 + 0.1 * b,
		},
	],
	"1.10": [
		{
			name: "D",
			max: (t) => 5,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 1 + 0.25 * b,
		},
		{
			name: "C",
			max: (t) => 4,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 1 + 0.15 * b,
		},
		{
			name: "B",
			max: (t) => 3,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 1 + 0.1 * b,
		},
	],
	1.11: [
		{
			name: "D",
			max: (t) => (t > 3 ? 5 : ""),
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) =>
				t > 3 ? 1 + 1 * b : 1 + 0.6 * b,
		},
		{
			name: "C",
			max: (t) => (t > 3 ? 4 : ""),
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) =>
				t > 3 ? 0.3 * b + 1 : 0.6 * b + 1,
		},
		{
			name: "B",
			max: (t) => (t > 3 ? 3 : ""),
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) =>
				t > 3 ? 0.1 * b + 1 : 0.2 * b + 1,
		},
	],
	1.12: [
		{
			name: "D",
			msg: (t) => msg.V_MSG,
			calculation: (self, t, x, b, weldingtype) => 90,
			max: () => {},
		},
		{
			name: "C",
			msg: (t) => msg.V_MSG,
			calculation: (self, t, x, b, weldingtype) =>
				weldingtype == "FW" ? 100 : 110,
			max: () => {},
		},
		{
			name: "B",
			msg: (t) => msg.V_MSG,
			calculation: (self, t, x, b, weldingtype) =>
				weldingtype == "FW" ? 110 : 150,
			max: () => {},
		},
	],
	1.13: [
		{
			name: "D",
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.2 * b,
			max: () => {},
		},
		{
			name: "C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	1.14: [
		{
			name: "D",
			max: (t) => (t > 3 ? 2 : ""),
			msg: (t) => msg.SHORT,
			calculation: (self, t, x, b, weldingtype) => 0.25 * t,
		},
		{
			name: "C",
			max: (t) => (t > 3 ? 1 : ""),
			msg: (t) => msg.SHORT,
			calculation: (self, t, x, b, weldingtype) => 0.1 * t,
		},
		{
			name: "B",
			max: (t) => (t > 3 ? 0.5 : ""),
			msg: (t) => (t > 3 ? msg.SHORT : msg.FORBIDDEN),
			calculation: (self, t, x, b, weldingtype) => 0.05 * t,
		},
	],
	1.15: [
		{
			name: "D, C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	1.16: [
		{
			name: "D",
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 2 + 0.2 * x,
			max: () => {},
		},
		{
			name: "C",
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 2 + 0.15 * x,
			max: () => {},
		},
		{
			name: "B",
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 1.5 + 0.15 * x,
			max: () => {},
		},
	],
	1.17: [
		{
			name: "D",
			max: (t) => (t > 3 ? 2 : ""),
			msg: (t) => (t > 3 ? msg.SHORT : msg.H_MSG),
			calculation: (self, t, x, b, weldingtype) =>
				t > 3 ? 0.2 * t : 0.2 + 0.1 * t,
		},
		{
			name: "C",
			max: (t) => (t > 3 ? 1 : ""),
			msg: (t) => msg.SHORT,
			calculation: (self, t, x, b, weldingtype) => 0.1 * t,
		},
		{
			name: "B",
			max: (t) => (t > 3 ? 0.5 : ""),
			msg: (t) => (t > 3 ? msg.SHORT : msg.FORBIDDEN),
			calculation: (self, t, x, b, weldingtype) => 0.05 * t,
		},
	],
	1.18: [
		{
			name: "D",
			msg: (t) => msg.PARTLY,
			calculation: () => {},
			max: () => {},
		},
		{
			name: "C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	1.19: [
		{
			name: "D",
			msg: (t) => msg.ALLOWED,
			calculation: () => {},
			max: () => {},
		},
		{
			name: "C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	"1.20": [
		{
			name: "D",
			max: (t) => (t > 3 ? 2 : ""),
			msg: (t) => msg.SHORT,
			calculation: (self, t, x, b, weldingtype) =>
				t > 3 ? 0.3 + 0.1 * x : 0.2 + 0.1 * x,
		},
		{
			name: "C",
			max: (t) => (t > 3 ? 1 : ""),
			msg: (t) => msg.SHORT,
			calculation: (self, t, x, b, weldingtype) =>
				t > 3 ? 0.3 + 0.1 * x : 0.2,
		},
		{
			name: "B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	1.21: [
		{
			name: "D",
			msg: (t) => msg.ALLOWED,
			calculation: () => {},
			max: () => {},
		},
		{
			name: "C",
			max: (t) => 4,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 1 + 0.2 * x,
		},
		{
			name: "B",
			max: (t) => 3,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 1 + 0.15 * x,
		},
	],
	1.22: [
		{
			name: "D, C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	1.23: [
		{
			name: "D, C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	1.24: [
		{
			name: "D, C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	2.6: [
		{
			name: "D",
			max: (t) => 4,
			msg: (t, x, weldingtype) => msg.H_MSG + "\ni ≤ " + Math.min(x, 75) + "mm",
			calculation: (self, t, x, b, weldingtype) => 0.4 * x,
		},
		{
			name: "C",
			max: (t) => 3,
			msg: (t, x, weldingtype) =>
				weldingtype == "FW"
					? msg.H_MSG + "\ni ≤ " + Math.min(x, 75) + "mm"
					: msg.H_MSG + "\ni ≤ " + Math.min(x, 50) + "mm",
			calculation: (self, t, x, b, weldingtype) => 0.3 * x,
		},
		{
			name: "B",
			max: (t) => 2,
			msg: (t, x, weldingtype) =>
				weldingtype == "FW"
					? msg.H_MSG + "\ni ≤ " + Math.min(x, 75) + "mm"
					: msg.H_MSG + "\ni ≤ " + Math.min(x, 25) + "mm",
			calculation: (self, t, x, b, weldingtype) => 0.2 * x,
		},
	],
	2.7: [
		{
			name: "D",
			msg: (t) => msg.PartlyForbidden,
			calculation: () => {},
			max: () => {},
		},
		{
			name: "C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	2.8: [
		{
			name: "D",
			max: (t) => (t > 3 ? 2 : ""),
			msg: (t) => msg.HL_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.2 * t,
		},
		{
			name: "C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	2.9: [
		{
			name: "D",
			max: (t) => 4,
			msg: (t, x, weldingtype) => msg.H_MSG + "\ni ≤ " + Math.min(x, 75) + "mm",
			calculation: (self, t, x, b, weldingtype) => 0.4 * x,
		},
		{
			name: "C",
			max: (t) => 3,
			msg: (t, x, weldingtype) => msg.H_MSG + "\ni ≤ " + Math.min(x, 50) + "mm",
			calculation: (self, t, x, b, weldingtype) => 0.3 * x,
		},
		{
			name: "B",
			max: (t) => 2,
			msg: (t, x, weldingtype) => msg.H_MSG + "\ni ≤ " + Math.min(x, 25) + "mm",
			calculation: (self, t, x, b, weldingtype) => 0.2 * x,
		},
	],
	"2.10": [
		{
			name: "D",
			max: (t) => 4,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.4 * x,
		},
		{
			name: "C",
			max: (t) => 3,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.3 * x,
		},
		{
			name: "B",
			max: (t) => 2,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.2 * x,
		},
	],
	2.11: [
		{
			name: "D, C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	2.12: [
		{
			name: "D",
			max: (t) => 4,
			msg: (t) => msg.SHORT,
			calculation: (self, t, x, b, weldingtype) => 0.4 * x,
		},
		{
			name: "C, B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	2.13: [
		{
			name: "D",
			max: (t) => 2,
			msg: (t) => msg.SHORT,
			calculation: (self, t, x, b, weldingtype) => 0.2 * x,
		},
		{
			name: "C",
			max: (t) => 1.5,
			msg: (t) => msg.SHORT,
			calculation: (self, t, x, b, weldingtype) => 0.1 * x,
		},
		{
			name: "B",
			msg: (t) => msg.FORBIDDEN,
			calculation: () => {},
			max: () => {},
		},
	],
	"3.1 Kun for plader": [
		{
			name: "D",
			max: (t) => (t > 3 ? 5 : ""),
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.2 + 0.25 * t,
		},
		{
			name: "C",
			max: (t) => (t > 3 ? 4 : ""),
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.2 + 0.15 * t,
		},
		{
			name: "B",
			max: (t) => (t > 3 ? 3 : ""),
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.2 + 0.1 * t,
		},
	],
	"3.1 Kun for rør": [
		{
			name: "D",
			max: (t) => 4,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.5 * t,
		},
		{
			name: "C",
			max: (t) => 3,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.5 * t,
		},
		{
			name: "B",
			max: (t) => 2,
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) => 0.5 * t,
		},
	],
	3.2: [
		{
			name: "D",
			max: (t) => (t > 3 ? 4 : ""),
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) =>
				t > 3 ? 1 + 0.3 * x : 0.5 + 0.1 * x,
		},
		{
			name: "C",
			max: (t) => (t > 3 ? 3 : ""),
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) =>
				t > 3 ? 0.5 + 0.2 * x : 0.3 + 0.1 * x,
		},
		{
			name: "B",
			max: (t) => (t > 3 ? 2 : ""),
			msg: (t) => msg.H_MSG,
			calculation: (self, t, x, b, weldingtype) =>
				t > 3 ? 0.2 + 0.1 * x : 0.2 + 0.1 * x,
		},
	],
};

const callFunction = () => {
	return [
		// 1.1
		{
			id: "1.1",
			error: "Revne",
			type: ["FW", "BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_one],
				remark: [],
			},
		},
		// 1.2
		{
			id: "1.2",
			error: "Kraterevne",
			type: ["FW", "BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_two],
				remark: [],
			},
		},
		// 1.3
		{
			id: "1.3",
			error: "Overfladepore",
			type: ["FW", "BW"],
			variables: ["t", "x"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_three],
				remark: ["Max. dimension af enkelt pore: 0.5 til 3mm"],
			},
		},
		// 1.4
		{
			id: "1.4",
			error: "Åben Kraterpore",
			type: ["FW", "BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_four],
				remark: [],
			},
		},
		// 1.5
		{
			id: "1.5",
			error: "Bindingsfejl",
			type: ["FW", "BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_five],
				remark: [],
			},
		},
		// 1.6
		{
			id: "1.6",
			error: "RodFejl",
			type: ["BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_six],
				remark: [],
			},
		},
		// 1.7
		{
			id: "1.7",
			error: "Sidekærv",
			type: ["FW", "BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_seven_bw, image_one_seven_fw],
				remark: [],
			},
		},
		// 1.8
		{
			id: "1.8",
			error: "Krympefuge(rodkærv)",
			type: ["BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_eight],
				remark: [],
			},
		},
		// 1.9
		{
			id: "1.9",
			error: "Oversvulst(stumpsøm)",
			type: ["BW"],
			variables: ["t", "b"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_nine],
				remark: [],
			},
		},
		// 1.10
		{
			id: "1.10",
			error: "Konveks sømoverflade(kantsøm)",
			type: ["FW"],
			variables: ["t", "b"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_ten],
				remark: [],
			},
		},
		// 1.11
		{
			id: "1.11",
			error: "Gennomløb",
			type: ["BW"],
			variables: ["t", "b"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_eleven],
				remark: [],
			},
		},
		// 1.12
		{
			id: "1.12",
			error: "Forkert overgang",
			type: ["BW", "FW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_twelve_bw],
				remark: [],
			},
		},
		// 1.13
		{
			id: "1.13",
			error: "Overløbning af svejsemetal",
			type: ["BW"],
			variables: ["t", "b"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_thirteen],
				remark: [],
			},
		},
		// 1.14
		{
			id: "1.14",
			error: "Nedløbet svejsemetal eller Manglende opfyldning",
			type: ["FW", "BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_fourteen],
				remark: [],
			},
		},
		// 1.15
		{
			id: "1.15",
			error: "Gennom-brænding",
			type: ["BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_fifteen],
				remark: ["Hul i svejsesømmen"],
			},
		},
		// 1.16
		{
			id: "1.16",
			error: "Ulige store kateter(z-mål) ved kantsøm",
			type: ["FW"],
			variables: ["t", "x"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_sixteen],
				remark: [],
			},
		},
		// 1.17
		{
			id: "1.17",
			error: "Indadhvælvning i roden",
			type: ["bw"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_seventeen],
				remark: [],
			},
		},
		// 1.18
		{
			id: "1.18",
			error: "Porøsitet i rodvulst",
			type: ["BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [],
				remark: [
					"Svampelignede udseende af svejseroden på grund af bobler i svejsemetallet ved størkningen. (fx mangelfuld baggas)",
				],
			},
		},
		// 1.19
		{
			id: "1.19",
			error: "Fejl ved genstart",
			type: ["BW", "FW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_nineteen],
				remark: [],
			},
		},
		// 1.20
		{
			id: "1.20",
			error: "Utilstrækkeligt a-mål (For lille a-mål)",
			type: ["FW"],
			variables: ["t", "x"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_twenty],
				remark: [],
			},
		},
		// 1.21
		{
			id: "1.21",
			error: "Uforholdsmæssigt stort s-mål",
			type: ["FW"],
			variables: ["t", "x"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_one_twentyone],
				remark: [],
			},
		},
		// 1.22
		{
			id: "1.22",
			error: "Tændsår",
			type: ["BW", "FW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [],
				remark: [
					"Lokalt beskadigelse af grundmaterialets overflade ved siden af svejsesømme. Opstår ved tænding af lysbuen uden for svejsefugen.",
				],
			},
		},
		// 1.23
		{
			id: "1.23",
			error: "Sprøjt",
			type: ["BW", "FW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [],
				remark: [
					"Dråber af svejsemetal eller tilsatsmateriale, der klæber sig fast på grundmaterialet eller på svejsningen.",
				],
			},
		},
		// 1.24
		{
			id: "1.24",
			error: "Anløbsfarve(misfarvning)",
			type: ["BW", "FW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [],
				remark: [
					"Let oxideret overflade i en svejsezone, fx rustfrit stål.Forårsaget af svejsevarmen og/eller mangel på beskyttelse.",
				],
			},
		},
		// 2.6
		{
			id: "2.6",
			error: "Lang pore ormehuller",
			type: ["FW", "BW"],
			variables: ["t", "x"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_two_six],
				remark: [],
			},
		},
		// 2.7
		{
			id: "2.7",
			error: "Sugning",
			type: ["FW", "BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_two_seven],
				remark: [],
			},
		},
		// 2.8
		{
			id: "2.8",
			error: "Kraterpore",
			type: ["FW", "BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_two_eight],
				remark: [],
			},
		},
		// 2.9
		{
			id: "2.9",
			error:
				"Indeslutning, Slaggeindeslutninger, Flux eller Oxidindeslutninger",
			type: ["FW", "BW"],
			variables: ["t", "x"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_two_nine],
				remark: [],
			},
		},
		// 2.10
		{
			id: "2.10",
			error: "Metalliske indeslutninger undtagen kobber",
			type: ["FW", "BW"],
			variables: ["t", "x"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [],
				remark: [],
			},
		},
		// 2.11
		{
			id: "2.11",
			error: "Kobberindeslutninger",
			type: ["FW", "BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [],
				remark: [],
			},
		},
		// 2.12
		{
			id: "2.12",
			error: "Bindingsfejl i siden, mellem de enkelte strenge eller i riden",
			type: ["FW", "BW"],
			variables: ["t", "x"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [
					image_two_twelve_one,
					image_two_twelve_two,
					image_two_twelve_three,
				],
				remark: [],
			},
		},
		// 2.13
		{
			id: "2.13",
			error: "Mangelfuld indtrængning",
			type: ["BW", "FW"],
			variables: ["t", "x"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [],
				remark: [],
			},
		},
		// 3.1 kun plader
		{
			id: "3.1 Kun for plader",
			error: "Kantforsætning mellem plader",
			type: ["BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_three_one_plade],
				remark: [],
			},
		},
		// 3.1 kun rør
		{
			id: "3.1 Kun for rør",
			error: "Kantforsætning mellem rør rundsømme",
			type: ["BW"],
			variables: ["t"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_three_one_tube],
				remark: [],
			},
		},
		// 3.2
		{
			id: "3.2",
			error: "Dårlig tilpasning af rodspalten, kantsømme",
			type: ["FW"],
			variables: ["t", "x"],
			calc: (self, t, x, b, weldingtype) =>
				testItem(self, t, x, b, weldingtype),
			details: {
				image: [image_three_two],
				remark: [],
			},
		},
	];
};

const concatItem = (message, value, max) => {
	if (max) value = Math.min(value, max);
	value = +parseFloat(value).toFixed(maxDecimalPoints);
	return String(message).replace("$$", value);
};

const testItem = (self, t, x, b, weldingtype) => {
	const throwBack = [];

	if (!self.type.includes(weldingtype)) return null;
	if (t < 0.5) return err.T_ERR;
	if (!x && weldingtype == "FW" && self.variables.includes("x"))
		return err.A_ERR;
	if (!x && weldingtype == "BW" && self.variables.includes("x"))
		return err.S_ERR;
	if (!b && self.variables.includes("b")) return err.B_ERR;

	for (const item of calculations[self.id]) {
		throwBack.push({
			name: item.name,
			message: concatItem(
				item.msg(t, x, weldingtype) ||
					item.calculation(self, t, x, b, weldingtype) ||
					"",
				item.calculation(self, t, x, b, weldingtype) || "",
				item.max(t) || ""
			),
		});
	}
	return throwBack;
};

export default callFunction;
