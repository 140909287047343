import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonInput,
	IonSelect,
	IonSelectOption,
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
	IonItemDivider,
	IonText,
	IonModal,
	IonButton,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "./Home.css";
import Logo from "../img/logo.svg";
import Techcollege from "../img/techcollege.svg";
import callFunction from "../Assets/Database/database_Remastered";

import image_one_one from "../Assets/errorTypes/fejltype1_1.png";

const Home: React.FC<any> = () => {
	const [thickness, setThickness] = useState(Number);
	/* const [bwThickness, setbwThickness] = useState(Number); */
	const [xThickness, setXThickness] = useState(Number);
	const [weldingtype, setWeldingtype] = useState(String);
	const [width, setWidth] = useState(Number);
	// const [angle, setAngle] = useState(Number);
	const [modalOpen, setModalOpen] = useState(false);
	const [choosenElement, setChoosenElement] = useState({
		name: "",
		message: "",
		details: { id: Number, error: "", image: [], remark: [] },
	});

	const handleThickness = (value: any) => {
		setThickness(value.currentTarget.value.replace(",", "."));
	};
	/* 	const handlebwThickness = (value: any) => {
		setbwThickness(value.currentTarget.value.replace(",", "."));
	}; */
	const handleXThickness = (value: any) => {
		setXThickness(value.currentTarget.value.replace(",", "."));
	};
	const handleWeldingtype = (value: any) => {
		value.preventDefault();
		setWeldingtype(value.currentTarget.value);
	};
	const handleWidth = (value: any) => {
		setWidth(value.currentTarget.value.replace(",", "."));
	};
	// const handleAngle = (value: any) => { setAngle(value.currentTarget.value) };

	const handleChooseElement = (value: any) => {
		setChoosenElement(value);
		setModalOpen(true);
	};

	//darktheme enable
	const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

	toggleDarkTheme(prefersDark.matches);

	// Listen for changes to the prefers-color-scheme media query
	prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

	// Add or remove the "dark" class based on if the media query matches
	function toggleDarkTheme(shouldAdd: any) {
		document.body.classList.toggle("dark", shouldAdd);
	}

	let usedArray = callFunction();

	return (
		<IonPage className="mainpage">
			{document.querySelector("ion-app")?.addEventListener("click", () => {
				if (modalOpen) setModalOpen(false);
			})}
			<IonHeader>
				<IonToolbar>
					<IonTitle className="Title-logo">
						<img className="Logo" src={Logo} alt=""></img>
					</IonTitle>
					<IonTitle className="Title-Techcollege">
						<img src={Techcollege} alt=""></img>
					</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent className="Content-section">
				<IonList>
					<IonItem className="Ion-items">
						<IonSelect
							className="Select"
							value={weldingtype}
							placeholder="Vælg svejsning"
							onIonChange={(e) => {
								handleWeldingtype(e);
							}}
						>
							<IonSelectOption value="FW">Kantsøm</IonSelectOption>
							<IonSelectOption value="BW">Stumpsøm</IonSelectOption>
						</IonSelect>
					</IonItem>
					<IonItem className="Ion-items">
						<IonLabel className="Input-label">Pladetykkelse</IonLabel>
						<IonInput
							className="Input"
							onIonChange={(e) => {
								handleThickness(e);
							}}
							placeholder="Pladetykkelse i mm"
						></IonInput>
					</IonItem>

					<IonItem className="Ion-items">
						<IonLabel className="Input-label">
							{weldingtype != "BW" ? "A-mål" : "Stumpsøm tykkelse"}
						</IonLabel>
						<IonInput
							className="Input"
							onIonChange={(e) => {
								handleXThickness(e);
							}}
							placeholder={
								weldingtype != "BW" ? "A-mål i mm" : "Stumpsøm tykkelse i mm"
							}
						></IonInput>
					</IonItem>
					{/* 						<IonItem className="Ion-items">
							<IonLabel className="Input-label">Stumpsøm tykkelse</IonLabel>
							<IonInput
								className="Input"
								onIonChange={(e) => {
									handlebwThickness(e);
								}}
								placeholder="Stumpsøm tykkelse i mm"
							></IonInput>
						</IonItem> */}
					<IonItem className="Ion-items">
						<IonLabel className="Input-label">Bredde</IonLabel>
						<IonInput
							className="Input"
							onIonChange={(e) => {
								handleWidth(e);
							}}
							placeholder="Bredde i mm"
						></IonInput>
					</IonItem>
				</IonList>

				<IonHeader>
					<IonToolbar className="Ion-toolbar">
						<IonText className="Ion-errors">Fejlmeddelelser</IonText>
					</IonToolbar>
				</IonHeader>
				{weldingtype &&
					usedArray &&
					usedArray.map((item: any, index: any) => {
						if (item.calc(item, thickness, xThickness, width, weldingtype)) {
							return (
								<IonList
									key={index}
									onClick={() => {
										handleChooseElement(item);
									}}
									className="Ion-list"
								>
									<IonItemDivider className="Ion-divider">
										<IonLabel className="Ion-label">{item.id}</IonLabel>
										<IonText className="Ion-text">{item.error}</IonText>
									</IonItemDivider>

									{item.calc(item, thickness, xThickness, width, weldingtype) &&
										item
											.calc(item, thickness, xThickness, width, weldingtype)
											.map((element: any, index: any) => {
												return (
													<IonItem className="show" key={index}>
														<IonGrid className="Ion-col">
															<IonRow>
																<IonCol className="Ion-col">
																	{element.name}
																</IonCol>
																<IonCol className="Ion-col Ion-col-message">
																	{element.message}
																</IonCol>
															</IonRow>
														</IonGrid>
													</IonItem>
												);
											})}
								</IonList>
							);
						}
					})}

				{choosenElement && choosenElement.name === "Fejl" ? null : (
					<IonModal isOpen={modalOpen}>
						<IonHeader>
							<IonToolbar className="Ion-toolbar-modal">
								<IonTitle className="Ion-title">
									{choosenElement.details.id} <br />
									{choosenElement.details.error}
								</IonTitle>
							</IonToolbar>
						</IonHeader>
						{choosenElement.details.image &&
							choosenElement.details.image.map((item: any, index: any) => {
								return <img key={index} src={item} alt="" />;
							})}
						{choosenElement.details.remark &&
							choosenElement.details.remark.map((item: any, index: any) => {
								return <p key={index}>{item}</p>;
							})}
						<IonButton
							expand="full"
							onClick={(e) => {
								setModalOpen(false);
							}}
						>
							luk fane
						</IonButton>
					</IonModal>
				)}
			</IonContent>
		</IonPage>
	);
};

export default Home;
